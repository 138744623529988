<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-dialog v-model="dialog" width="800" persistent>
      <v-card class="rounded-lg pl-10 pr-3 pb-6" width="800">
        <v-card-title>
          <span style="color: DarkCyan" class="text-h5"
            >Adicionar Instagram</span
          >

          <v-spacer></v-spacer>

          <v-btn color="white" text :to="{ name: 'Instagram' }">
            <v-icon color="#5E6278"> mdi-close </v-icon>
          </v-btn>
        </v-card-title>

        <v-divider class="mb-5"></v-divider>

        <v-row>
          <v-col cols="8">
            <BaseInputValidator
              type="textField"
              label="Nome:*"
              clearable
              :rules="[rules.required]"
              rounded
              filled
              fieldLayout="filled"
              v-model="form.name"
              dense
              background-color="#F5F8FA"
            ></BaseInputValidator>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <BaseInputValidator
              type="textField"
              label="Instagram:*"
              clearable
              :rules="[rules.required]"
              rounded
              filled
              fieldLayout="filled"
              v-model="form.url"
              dense
              background-color="#F5F8FA"
            ></BaseInputValidator>
          </v-col>
        </v-row>

        <v-divider class="pb-2"></v-divider>

        <v-row class="mt-2">
          <v-spacer></v-spacer>
          <v-col class="mr-4" cols="2">
            <v-btn
              color="primary"
              x-large
              class="rounded white--text"
              @click="verifyTypeForm"
              :disabled="invalid"
            >
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BaseInputValidator from "../../components/UI/BaseInputValidator";
import ContentService from "../contents/service";
export default {
  name: "CreateInstagram",
  data() {
    return {
      form: {
        name: "",
        url: "",
        type: "INSTAGRAM",
      },
      dialog: true,
      rules: {
        required: (value) => !!value || "Obrigatório.",
      },
    };
  },
  components: {
    ValidationObserver,
    BaseInputValidator,
  },
  methods: {
    async sendInstagramInfo() {
      try {
        const instagram = await ContentService.createContent(this.form);
        return instagram;
      } catch (error) {
        console.error(error);
      }
    },
    async verifyTypeForm() {
      if (this.typeForm === "EditInstagram") {
        const instagram = await this.updateInstagram();
        this.$router.push({ name: "Instagram" });
        this.$emit("created", {
          instagram: instagram,
          typeForm: this.typeForm,
        });
      } else {
        const instagram = await this.sendInstagramInfo();
        this.$router.push({ name: "Instagram" });
        this.$emit("created", {
          instagram: instagram,
          typeForm: this.typeForm,
        });
      }
    },
    async updateInstagram() {
      try {
        const instagram = await ContentService.updateContent(
          this.form,
          this.$route.params.id
        );
        return instagram;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    this.typeForm = this.$route.name;
    if (this.typeForm === "EditInstagram") {
      const instagram = await ContentService.getContentById(
        this.$route.params.id
      );
      this.form = instagram.data;
    }
  },
};
</script>
